<template>
  <!--
    Overview of RUES elements:
    https://docs.google.com/spreadsheets/d/1MItvXOtUgQWXzXfxEZnByuwEfC1pYGXKT00VEp8Lh90/edit?gid=1852215422#gid=1852215422
    https://docs.google.com/spreadsheets/d/1xTJl7LvL1K1xMV38jUXj2e16eivDmE7oA6tNgb-Bc9s/edit?gid=0#gid=0
  -->
  <RuesText v-if="ruesElement.type === 'Text2'" :item="ruesElement?.data" />
  <RuesText v-if="ruesElement.type === 'Text3'" :item="ruesElement?.data" />
  <RuesText v-if="ruesElement.type === 'Text4'" :item="ruesElement?.data" />
  <RuesText v-if="ruesElement.type === 'Text6'" :item="ruesElement?.data" />
  <RuesText v-if="ruesElement.type === 'StartseiteText'" :item="ruesElement?.data" />
  <RuesText v-if="ruesElement.type === 'TextWithoutStyles'" :item="ruesElement?.data" />
  <RuesHighlight v-if="ruesElement.type === 'InfoInsel'" :item="ruesElement?.data" />
  <RuesText v-if="ruesElement.type === 'TextInsel'" :item="ruesElement?.data" />
  <RuesCard v-if="ruesElement.type === 'URLTeaser'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesCard v-if="ruesElement.type === 'URLTeaser2'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesCard v-if="ruesElement.type === 'URLTeaser3'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesCard v-if="ruesElement.type === 'URLTeaser4'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesCard v-if="ruesElement.type === 'ContentTeaser'" :item="ruesElement?.data" />
  <RuesPictureCard v-if="ruesElement.type === 'BilderlinksMitText'" :item="ruesElement?.data" />
  <RuesFaq v-if="ruesElement.type === 'FAQ'" :item="ruesElement?.data" />
  <RuesPicture v-if="ruesElement.type === 'Bildbox'" :item="ruesElement?.data" />
  <RuesButton v-if="ruesElement.type === 'Searchlink'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesButton v-if="ruesElement.type === 'Startpage'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesButton v-if="ruesElement.type === 'FlexiblerSecondaryButton'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesNewsletter v-if="ruesElement.type === 'Newsletter6'" :item="ruesElement?.data" />
  <RuesLinkCollection v-if="ruesElement.type === 'Liste'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesLinkCollection v-if="ruesElement.type === 'GEO'" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesMap v-if="ruesElement.type === 'Karte'" :item="ruesElement?.data" />
  <RuesHistogramm v-if="ruesElement.type === 'Histogramm'" :item="ruesElement?.data" />
  <RuesTrust v-if="ruesElement.type === 'Trust'" :item="ruesElement?.data" />
  <RuesMap v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'Karte'" :item="ruesElement?.data" />
  <RuesTrust v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'VertrauensschaffendeMassnahmen'" :item="ruesElement?.data" />
  <RuesCardMietrad v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'Fahrradteaser'" :item="ruesElement?.data" />
  <RuesCardMietwagen v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'MietwagenSylt'" :item="ruesElement?.data" />
  <RuesCardReviews v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'ObjektBewertungLinks'" :item="ruesElement?.data" />
  <RuesCardNewProduct v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'NeuesteObjekte'" :item="ruesElement?.data" />
  <RuesCardReviews v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'BewertungTeaser'" :item="ruesElement?.data" />
  <RuesTags v-if="ruesElement.type === 'TAG'" :item="ruesElement?.data" :sidebar="sidebar" :special-container="specialContainer" />
  <RuesSearch v-if="ruesElement.type === 'Suchformular6'" :item="ruesElement?.data" />
  <RuesCardMietrad v-if="ruesElement.type === 'FahrradTeaser'" :item="ruesElement?.data" />
  <RuesMultiCardLinkAccordion v-if="ruesElement.type === 'Urlaubsseiten'" :item="ruesElement?.data" />
  <RuesHeadlineLinkCollection v-if="ruesElement.type === 'Radius'" :item="ruesElement?.data" />

  <!-- Elements with a Skeleton: -->
  <RuesProductCarousel v-if="ruesElement.type === 'ObjektTeaser4'" :skeleton="!ruesElement?.data?.client" :item="ruesElement?.data" :type="ruesElement.type" :search-params="props.searchParams" />
  <RuesProductLastminuteCarousel v-if="ruesElement.type === 'Lastminute'" :skeleton="!ruesElement?.data?.client" :item="ruesElement?.data" />
  <RuesProductReviews v-if="ruesElement.type === 'ObjektTeaserMitBewertung'" :skeleton="!ruesElement?.data?.client" :item="ruesElement?.data" :type="ruesElement.type" />
  <RuesUSP v-if="ruesElement.type === 'USP'" :skeleton="!ruesElement?.data?.client" :item="ruesElement?.data" />
  <RuesProductCarousel v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'Fotolinks'" :skeleton="!ruesElement?.data?.client" :item="ruesElement?.data" />
  <RuesCardLastminute v-if="ruesElement.type === 'StartseitenElement' && ruesElement?.data?.element === 'Schweinchenkasten'" :skeleton="!ruesElement?.data?.client" :item="ruesElement?.data" />
</template>

<script setup lang="ts">
const props = defineProps({
  ruesElement: {
    type: Object,
    default: null
  },
  searchParams: {
    type: Object,
    default: null
  },
  sidebar: {
    type: Boolean,
    default: false
  },
  specialContainer: {
    type: Boolean,
    default: false
  }
})
</script>
