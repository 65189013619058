<template>
  <RuesHeaderForMagazin
    v-if="['urlaubsmagazin', 'vermieter'].includes(contentGroup)"
    :content-group="contentGroup"
  />
  <RuesHeaderForRuesPage
    v-if="props.pagedata.urldata.pagetype_data.type === 'rues_page'"
    :header-details="props.pagedata.details?.header"
  />
  <RuesHeaderForHolidayPage
    v-if="props.pagedata.details?.header && props.pagedata.urldata.pagetype_data.type === 'holiday_page'"
    :header-details="props.pagedata.details?.header"
  />
  <!-- Wrapper for the first two containers if contentGroup is a special one -->
  <div v-if="isSpecialContentGroup" class="container 2xl:max-w-screen-xl mx-auto px-6 mb-5 mt-12 text-left grid grid-cols-1 xl:grid-cols-3 gap-4">
    <div v-for="(ruesContainer, index) in rearrangedContainers" :key="ruesContainer.position" :class="{'col-span-2': index === 0, 'col-span-1 xl:ml-5': index === 1}">
      <div class="grid grid-cols-12 gap-x-4 gap-y-8" :class="{'sticky top-1': index === 1}">
        <template v-for="ruesElement in ruesContainer.elements" :key="ruesElement.position">
          <RuesComponents :sidebar="index === 1" :special-container="index === 0" :rues-element="ruesElement" :search-params="props.pagedata.details?.header?.search_element?.data?.params" />
        </template>
      </div>
    </div>
  </div>

  <div v-for="ruesContainer in (isSpecialContentGroup ? props.pagedata.details.containers.slice(2) : props.pagedata.details.containers)" :key="ruesContainer.position" class="container 2xl:max-w-screen-xl mx-auto grid grid-cols-12 gap-x-4 gap-y-8 px-6 mb-5 mt-12 text-left">
    <UiHeadline v-if="ruesContainer.data?.header" type="h2" design="h1" class="col-span-12">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="ruesContainer.data?.header" />
      <!-- eslint-enable vue/no-v-html -->
    </UiHeadline>
    <template v-for="ruesElement in ruesContainer.elements" :key="ruesElement.position">
      <RuesComponents :rues-element="ruesElement" :search-params="props.pagedata.details?.header?.search_element?.data?.params" />
    </template>
  </div>
</template>

<script setup lang="ts">
const { $gtm } = useNuxtApp()

const props = defineProps({
  pagedata: {
    type: Object,
    default: null
  }
})

const route = useRoute()
const { $backend } = useNuxtApp()
const { data, status } = await $backend.pages.getPageByUrlPathFromClient(
  encodeURI(route.path),
  encodeURI(route.fullPath)
)
const skeletonElements = ['Lastminute', 'Topinserate', 'ObjektTeaser4', 'ObjektTeaserMitBewertung', 'StartseitenElement', 'USP']
const skeletonStartseitenElements = ['Schweinchenkasten', 'Fotolinks']
watch(status, () => {
  if (status.value !== 'success') {
    return
  }
  const serverContainers = (props.pagedata?.details?.containers ?? [])
  const clientContainers = (data.value?.data?.details?.containers ?? [])
  if (serverContainers.length !== clientContainers.length) {
    // eslint-disable-next-line no-console
    console.error('Containers not synced. Please invalidate the cache for this page')
    return
  }
  let i = -1
  for (const container of (clientContainers ?? [])) {
    i++
    const serverElements = (serverContainers[i].elements ?? [])
    const clientElements = (container.elements ?? [])
    if (serverElements.length !== clientElements.length) {
      // eslint-disable-next-line no-console
      console.error(`Elements of container ${i} not synced. Please invalidate the cache for this page`)
      continue
    }
    let j = -1
    for (const element of (clientElements ?? [])) {
      j++
      const serverElement = serverElements[j]
      const clientElement = element
      if (
        serverElement.type !== clientElement.type ||
        serverElement.size !== clientElement.size ||
        serverElement.data?.width !== clientElement.data?.width ||
        serverElement.data?.element !== clientElement.data?.element
      ) {
        // eslint-disable-next-line no-console
        console.error(`Element ${j} of container ${i} not synced. Please invalidate the cache for this page`)
        continue
      }
      if (!skeletonElements.includes(clientElement.type)) {
        continue
      }
      if (element.type === 'StartseitenElement' && !skeletonStartseitenElements.includes(element.data?.element)) {
        continue
      }

      clientElement.client = true
      clientElement.data.client = true

      // WARNING: CHANGING A PROP VALUE IS CONSIDERED AN ANTI-PATTERN. THIS IS A VERY SPECIAL CASE
      // eslint-disable-next-line
      props.pagedata.details.containers[i].elements.splice(j, 1, clientElement)
    }
  }
})

const contentGroupsMapping = {
  Urlaubsmagazin: 'urlaubsmagazin',
  Inselmagazin: 'inselmagazin',
  Urlaubsziele: 'urlaubsziele',
  Inspiration: 'inspiration',
  Vermietermagazin: 'vermieter',
  'Urlaubsziele mit Sidebar': 'magazin'
}

const pagetype = props.pagedata.urldata.pagetype_data.type
let contentGroup = ''

if (pagetype === 'holiday_page') {
  const headerBereich = props.pagedata.details.header.bereich
  contentGroup = contentGroupsMapping[headerBereich] || ''
} else if (pagetype === 'rues_page') {
  contentGroup = props.pagedata.urldata.path === '/' ? 'homepage' : 'rues'
}
const isSpecialContentGroup = computed(() => ['inselmagazin', 'urlaubsmagazin', 'vermieter', 'magazin'].includes(contentGroup))

const rearrangedContainers = [...props.pagedata.details.containers.slice(0, 2)]
if (isSpecialContentGroup.value && rearrangedContainers.length === 2) {
  rearrangedContainers.reverse()
}

if (contentGroup !== '') {
  $gtm.contentGroup.sendContentGroup(contentGroup)
}

useJsonLdProductWithAggregateRating().setData(props.pagedata?.details?.structured_data ?? null)

// Set cache-control headers for CloudFront
const nuxtApp = useNuxtApp()
if (import.meta.server) {
  // https://github.com/nuxt/nuxt/discussions/25425
  nuxtApp.ssrContext.event.node.res.setHeader('cache-control', 'max-age=0, s-maxage=86400, stale-while-revalidate=518400, stale-if-error=0')
}

</script>
